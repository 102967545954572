import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import { Column10, Column20, FieldCell, GridRow, GridRowsContainer, LabelCell, LocalNumberFieldExtraSmall, LocalNumberFieldSmall, LocalTextInputExtend, LocalTextInputGrow, Note, RadioButtonsWrapper, Row10, Row20, Row5, UnitText } from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import Checkbox from "../../../../core/components/Checkbox";
import { EcIrregularHolidayUnit } from "../../../../core/enums/employmentConditions";
import styled from "styled-components";
import Text from "../../../../core/components/Text";
import { Trans, useTranslation } from "react-i18next";

interface HolidaysVacationSectionProps extends JobFormSectionProps {}

const PaidLeavesDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  background-color: #f7f7f7;
`;

const HolidaysVacationSection: FunctionComponent<HolidaysVacationSectionProps> = ({
  job,
  onChange
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });

  return (
    <>
     <GridRowsContainer>
        {/* 年間休日数 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('annual_days_off')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall
                placeholder="123"
                value={job?.averageAnnualDaysOff}
                onValueChange={value => onChange?.({ 
                  averageAnnualDaysOff: value,
                  ecHolidaysAnnualNumberOfHolidays: value
                })}
              />
              <UnitText>{tCore('units.days')}</UnitText>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 定例休日 (with checkboxes) */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('regular_holidays')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column20>
              <Row20>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysMonday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysMonday: value })}
                >
                  {tCore('days.monday')}
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysTuesday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysTuesday: value })}
                >
                  {tCore('days.tuesday')}
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysWednesday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysWednesday: value })}
                >
                  {tCore('days.wednesday')}
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysThursday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysThursday: value })}
                >
                  {tCore('days.thursday')}
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysFriday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysFriday: value })}
                >
                  {tCore('days.friday')}  
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysSaturday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysSaturday: value })}
                >
                  {tCore('days.saturday')} 
                </Checkbox>
                <Checkbox
                  checked={!!job?.ecHolidaysFixedDaysSunday}
                  onValueChange={value => onChange?.({ ecHolidaysFixedDaysSunday: value })}
                >
                  {tCore('days.sunday')}
                </Checkbox>
              </Row20>
              <Checkbox
                checked={!!job?.ecHolidaysNationalHolidaysOfJapan}
                onValueChange={value => onChange?.({ ecHolidaysNationalHolidaysOfJapan: value })}
              >
                {tJob('national_holidays')}
              </Checkbox>
            </Column20>
          </FieldCell>
        </GridRow>

        {/* その他の定例休日 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('other_regular_holidays')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder="夏季休暇5日、年末年始3日"
              value={job?.ecHolidaysOtherFixedHolidays ?? ""}
              onTextChange={value => onChange?.({ ecHolidaysOtherFixedHolidays: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 非定例休日（単位）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('nonregular_holidays_unit')}
            </Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={
                  job?.ecHolidaysIrregularDaysUnit === EcIrregularHolidayUnit.Week
                }
                value={EcIrregularHolidayUnit.Week}
                onValueChange={value => onChange?.({ ecHolidaysIrregularDaysUnit: value })}
              >
                {tJob('irregular_holidays_unit.week')}
              </Radio>
              <Radio
                checked={
                  job?.ecHolidaysIrregularDaysUnit === EcIrregularHolidayUnit.Month
                }
                value={EcIrregularHolidayUnit.Month}
                onValueChange={value => onChange?.({ ecHolidaysIrregularDaysUnit: value })}
              >
                {tJob('irregular_holidays_unit.month')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 非定例休日（日数）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('nonregular_holidays_num_of_days')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall
                placeholder="2"
                value={job?.ecHolidaysIrregularDaysNumberOfDays}
                onValueChange={value => onChange?.({ ecHolidaysIrregularDaysNumberOfDays: value })}
              />
              <UnitText>{tCore('units.days')}</UnitText>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* その他の非定例休日 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('other_nonregular_holidays')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder="夏季休暇5日、年末年始3日"
              value={job?.ecHolidaysOtherIrregularHolidays ?? ""}
              onTextChange={value => onChange?.({ ecHolidaysOtherIrregularHolidays: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 年次有給休暇 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('annual_paid_leave')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <Note>{tJob('note_on_annual_paid_leave')}</Note>
              <Row5>
                <LocalNumberFieldSmall
                  placeholder="10"
                  value={job?.ecLeavesAnnualPaidLeavesAfter_6MonthsDays}
                  onValueChange={value => onChange?.({ ecLeavesAnnualPaidLeavesAfter_6MonthsDays: value })}
                />
                <UnitText>{tCore('units.days')}</UnitText>
              </Row5>
              <Checkbox
                checked={!!job?.ecLeavesAnnualPaidLeavesBefore_6Months}
                onValueChange={value => onChange?.({ ecLeavesAnnualPaidLeavesBefore_6Months: value })}
              >
                {tJob('annual_paid_leave_available')}
              </Checkbox>
              { job?.ecLeavesAnnualPaidLeavesBefore_6Months &&
                <PaidLeavesDetailContainer>
                  <Note>{tJob('note_on_annual_paid_leave')}</Note>
                  <Row10>
                    <Trans
                      i18nKey="job.months_passed"
                      components={{
                        work_months_input: (
                          <LocalNumberFieldExtraSmall 
                            placeholder="1"
                            value={job?.ecLeavesAnnualPaidLeavesBefore_6MonthsMonths}
                            onValueChange={value => onChange?.(
                              { ecLeavesAnnualPaidLeavesBefore_6MonthsMonths: value }
                            )}
                          />
                        ),
                        text: <Text />,
                      }}
                    />
                  </Row10>
                  <Row10>
                    <Trans
                      i18nKey="job.days_granted"
                      components={{
                        leave_days_input: (
                          <LocalNumberFieldExtraSmall 
                            placeholder="2"
                            value={job?.ecLeavesAnnualPaidLeavesBefore_6MonthsDays}
                            onValueChange={value => onChange?.(
                              { ecLeavesAnnualPaidLeavesBefore_6MonthsDays: value }
                            )}
                          />
                        ),
                        text: <Text />,
                      }}
                    />
                  </Row10>
                </PaidLeavesDetailContainer>
              }
            </Column10>
          </FieldCell>
        </GridRow>
        
        {/* その他の休暇 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('other_holidays')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column20>
              <Row10>
                <Text>{tJob("paid")}</Text>
                <LocalTextInputGrow
                    placeholder="傷病休暇、看護休暇"
                    value={job?.ecLeavesOtherPaidLeaves ?? ""}
                    onTextChange={value => onChange?.({ ecLeavesOtherPaidLeaves: value })}
                  />
              </Row10>
              <Row10>
                <Text>{tJob("unpaid")}</Text>
                <LocalTextInputGrow
                  placeholder="慶弔休暇"
                  value={job?.ecLeavesOtherUnpaidLeaves ?? ""}
                  onTextChange={value => onChange?.({ ecLeavesOtherUnpaidLeaves: value })}
                />
              </Row10>
            </Column20>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  )
}

export default HolidaysVacationSection;