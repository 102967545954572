import { FunctionComponent, useEffect, useState } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  SectionSubHeading,
  RadioButtonsContainer,
  Description,
  ItemsGap5,
} from "../../commonStyles";
import Text from "../../../../../core/components/Text";
import Radio from "../../../../../core/components/Radio";
import NumberField from "../../../../../core/components/NumberField";
import Select, { SelectOption } from "../../../../../core/components/Select";
import { DAY_OF_MONTH_ITEMS } from "../../../../../job/constants/options";
import { EcPaymentMethod } from "../../../../../core/enums/employmentConditions";
import { convertMonthsToOptions } from "../../../../../core/utils";
import MultiSelect from "../../../../../core/components/MultiSelect";
import { getMonths } from "../../../../../core/apis/option";
import { FormBlockSectionProps } from "../../types";
import styled from "styled-components";

interface CompensationSectionProps extends FormBlockSectionProps {}

const LocalSelect = styled(Select)`
  width: 81px;
`;

const CompensationSection: FunctionComponent<CompensationSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {
  const [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
  const [bonusMonths, setBonusMonths] = useState<string[]>([]);
  
  useEffect(() => {
    (async () => {
      const months = await getMonths();
      setMonthOptions(convertMonthsToOptions(months));
    })();
  }, []);

  useEffect(() => {
    setBonusMonths(ec?.bonusMonths?.map(bm => bm.id.toString()) ?? []);
  }, [ec?.bonusMonths])

  return (
    <FormSection>
      <SectionHeading>賃金</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>基本賃金（支給形態）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <Description>
            後述の「賃金の支払い」欄にて入力した値が表示されます
          </Description>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>基本賃金（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <Description>
            後述の「賃金の支払い」欄にて入力した値が表示されます
          </Description>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>諸手当（時間外労働の割増賃金は除く）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="皆勤手当、家族手当、住宅手当"
            value={ec?.compensationBenefits ?? ""}
            onTextChange={val => onChange?.({ compensationBenefits: val })}
          />
        </InputContainer>
      </FormRow>
      <SectionSubHeading>割増賃金率</SectionSubHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定時間外（法定超月60時間以内）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesOvertimeAboveLegalLte_60Hs}
            placeholder="25"
            onValueChange={val => onChange?.({ compensationExtraWageRatesOvertimeAboveLegalLte_60Hs: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定時間外（法定超月60時間超）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesOvertimeAboveLegalGt_60Hs}
            placeholder="50"
            onValueChange={val => onChange?.({ compensationExtraWageRatesOvertimeAboveLegalGt_60Hs: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定時間外（所定超）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesOvertimeAboveNormal}
            placeholder="25"
            onValueChange={val => onChange?.({ compensationExtraWageRatesOvertimeAboveNormal: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>休日（法定）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesHolidayLegal}
            placeholder="35"
            onValueChange={val => onChange?.({ compensationExtraWageRatesHolidayLegal: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>休日（法定以外）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesHolidayOtherThanLegal}
            placeholder="25"
            onValueChange={val => onChange?.({ compensationExtraWageRatesHolidayOtherThanLegal: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>深夜</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            value={ec?.compensationExtraWageRatesMidnight}
            placeholder="25"
            onValueChange={val => onChange?.({ compensationExtraWageRatesMidnight: val })}
          />
          %
        </InputContainer>
      </FormRow>
      <FormRow />
      <FormRow>
        <LabelContainer>
          <ItemLabel>締切日/支払日1</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemsGap5>
            <Text>締切日</Text>          
            <LocalSelect 
              value={ec?.compensationPayrollCutOffDate_1}
              placeholder="選択"
              onValueChange={val => onChange?.({ compensationPayrollCutOffDate_1: val })}
              options={DAY_OF_MONTH_ITEMS}
            />
            <Text>日</Text>
            <Text>/支払日</Text>
            <LocalSelect 
              value={ec?.compensationPayrollDate_1}
              placeholder="選択"
              onValueChange={val => onChange?.({ compensationPayrollDate_1: val })}
              options={DAY_OF_MONTH_ITEMS}
            />
            <Text>日</Text>
          </ItemsGap5>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>締切日/支払日2</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemsGap5>
            <Text>締切日</Text>          
            <LocalSelect 
              value={ec?.compensationPayrollCutOffDate_2}
              placeholder="選択"
              onValueChange={val => onChange?.({ compensationPayrollCutOffDate_2: val })}
              options={DAY_OF_MONTH_ITEMS}
            />
            <Text>日</Text>
            <Text>/支払日</Text>
            <LocalSelect 
              value={ec?.compensationPayrollDate_2}
              placeholder="選択"
              onValueChange={val => onChange?.({ compensationPayrollDate_2: val })}
              options={DAY_OF_MONTH_ITEMS}
            />
            <Text>日</Text>
          </ItemsGap5>
        </InputContainer>
      </FormRow>
      
      <FormRow>
        <LabelContainer>
          <ItemLabel>支払方法</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationPaymentMethod === EcPaymentMethod.BankTransfer}
              value={EcPaymentMethod.BankTransfer}
              onValueChange={val => onChange?.({ compensationPaymentMethod: val })}
            >
              口座振込
            </Radio>
            <Radio
              checked={ec?.compensationPaymentMethod === EcPaymentMethod.Cash}
              value={EcPaymentMethod.Cash}
              onValueChange={val => onChange?.({ compensationPaymentMethod: val })}
            >
              通貨払
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>控除</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationDeductions === true}
              onValueChange={val => onChange?.({ 
                compensationDeductions: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.compensationDeductions === false}
              onValueChange={val => onChange?.({ 
                compensationDeductions: false
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>昇給</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationWageRaise === true}
              onValueChange={val => onChange?.({ 
                compensationWageRaise: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.compensationWageRaise === false}
              onValueChange={val => onChange?.({ 
                compensationWageRaise: false
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      { ec?.compensationWageRaise &&
        <>
          <FormRow>
            <LabelContainer>
              <ItemLabel>昇給の時期・金額等</ItemLabel>
            </LabelContainer>
            <InputContainer>
              <ItemTextInput 
                placeholder="年2回査定（6月、12月）"
                value={ec?.compensationWageRaiseTimeAmountEtc ?? ""}
                onTextChange={val => onChange?.({ compensationWageRaiseTimeAmountEtc: val })}
              />
            </InputContainer>
          </FormRow>
        </>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>賞与</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationBonus === true}
              onValueChange={() => onChange?.({ 
                compensationBonus: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.compensationBonus === false}
              onValueChange={() => onChange?.({ 
                compensationBonus: false
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      { ec?.compensationBonus &&
        <>
          <FormRow>
            <LabelContainer>
              <ItemLabel>賞与の支給回数（年間）</ItemLabel>
            </LabelContainer>
            <InputContainer>
              <NumberField
                value={ec?.compensationBonusNumberOfPaymentsPerYear}
                placeholder="2"
                onValueChange={val => onChange?.({ compensationBonusNumberOfPaymentsPerYear: val })}
              />
              回
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>賞与の支給時期</ItemLabel>
            </LabelContainer>
            <InputContainer>
              <MultiSelect
                placeholder="選択する(複数可)"
                options={monthOptions}
                value={bonusMonths}
                onValueChange={val => {
                  setBonusMonths(val);
                  onChange?.({ bonusMonthIds: val })
                }}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>賞与の金額等</ItemLabel>
            </LabelContainer>
            <InputContainer>
              <ItemTextInput 
                placeholder="3〜5万円程度"
                value={ec?.compensationBonusAmountEtc ?? ""}
                onTextChange={val => onChange?.({ compensationBonusAmountEtc: val })}
              />
            </InputContainer>
          </FormRow>
        </>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>退職金</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationSeverancePayment === true}
              onValueChange={() => onChange?.({ 
                compensationSeverancePayment: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.compensationSeverancePayment === false}
              onValueChange={() => onChange?.({ 
                compensationSeverancePayment: false
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      { ec?.compensationSeverancePayment &&
        <FormRow>
          <LabelContainer>
            <ItemLabel>退職金の条件・金額等</ItemLabel>
          </LabelContainer>
          <InputContainer>
            <ItemTextInput 
              placeholder="退職金規程の支給基準による"
              value={ec?.compensationSeverancePaymentTimeAmountEtc ?? ""}
              onTextChange={val => onChange?.({ compensationSeverancePaymentTimeAmountEtc: val })}
            />
          </InputContainer>
        </FormRow>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>休業手当</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.compensationLeaveAllowance === true}
              onValueChange={() => onChange?.({ 
                compensationLeaveAllowance: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.compensationLeaveAllowance === false}
              onValueChange={val => onChange?.({ 
                compensationLeaveAllowance: false
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      { ec?.compensationLeaveAllowance &&
        <FormRow>
          <LabelContainer>
            <ItemLabel>休業手当の支給額（平均賃金の割合）</ItemLabel>
          </LabelContainer>
          <InputContainer>
            <ItemTextInput 
              placeholder="60%"
              value={ec?.compensationLeaveAllowanceRate ?? ""}
              onTextChange={val => onChange?.({ compensationLeaveAllowanceRate: val })}
            />
          </InputContainer>
        </FormRow>
      }
    </FormSection>
  );
};

export default CompensationSection;
