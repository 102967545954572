import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  LabelContainer,
  NoteInLabel,
  EmphasizedItemLabel,
  NoteOnSection,
  SectionNoteContainer,
  SectionHeadingLabel,
} from "../../commonStyles";
import CurrencyField from "../../../../../core/components/CurrencyField";
import { FormBlockSectionProps } from "../../types";
import Text from "../../../../../core/components/Text";
import NumberField from "../../../../../core/components/NumberField";
import styled from "styled-components";

interface BenefitsSectionProps extends FormBlockSectionProps {}

const LocalInputContainer = styled(InputContainer)`
  flex-direction: column;
  gap: 5px;
`;

const Paragraph = styled.p`
  width: 100%;
  margin: 0;
`;

const LocalText = styled(Text)`
  display: inline;
`;

const LocalNumberField = styled(NumberField)`
  text-align: right;
`;

const BenefitsSection: FunctionComponent<BenefitsSectionProps> = ({
  employmentConditions: ec,
  onChange,
}) => {

  return (
    <FormSection>
      <FormRow>   
        <LabelContainer>
          <SectionHeadingLabel>手当</SectionHeadingLabel>
        </LabelContainer>  
        <SectionNoteContainer>
          <NoteOnSection>※時間外労働の割増賃金は除く</NoteOnSection>
        </SectionNoteContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（名称）
          </ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="皆勤手当"
            value={ec?.benefitsBenefit_1Name ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_1Name: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_1Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_1Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="賃金計算期間中に欠勤無しの場合に支給"
            value={ec?.benefitsBenefit_1CalculationMethod ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_1CalculationMethod: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="皆勤手当"
            value={ec?.benefitsBenefit_2Name ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_2Name: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_2Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_2Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="賃金計算期間中に欠勤無しの場合に支給"
            value={ec?.benefitsBenefit_2CalculationMethod ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_2CalculationMethod: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput  
            placeholder="皆勤手当"
            value={ec?.benefitsBenefit_3Name ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_3Name: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_3Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_3Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="賃金計算期間中に欠勤無しの場合に支給"
            value={ec?.benefitsBenefit_3CalculationMethod ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_3CalculationMethod: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="皆勤手当"
            value={ec?.benefitsBenefit_4Name ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_4Name: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_4Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_4Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="賃金計算期間中に欠勤無しの場合に支給"
            value={ec?.benefitsBenefit_4CalculationMethod ?? ''}
            onTextChange={value => onChange?.({ benefitsBenefit_4CalculationMethod: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput
            placeholder="固定残業代"
            value={ec?.benefitsFixedOvertimePayName ?? ''}
            onTextChange={value => onChange?.({ benefitsFixedOvertimePayName: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsFixedOvertimePayAmount}
            onValueChange={value => onChange?.({ benefitsFixedOvertimePayAmount: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（支給要件）</ItemLabel>
        </LabelContainer>
        <LocalInputContainer>
          <Paragraph>
            <LocalText>時間外労働の有無にかかわらず、 </LocalText>
            <LocalNumberField
              placeholder="5"
              value={ec?.benefitsFixedOvertimePayPaymentRequirement_1}
              onValueChange={value => onChange?.({ benefitsFixedOvertimePayPaymentRequirement_1: value })}
            />
            <LocalText> 時間分の時間外手当として支給。</LocalText>
          </Paragraph>
          <Paragraph>
            <LocalNumberField
              placeholder="5"
              value={ec?.benefitsFixedOvertimePayPaymentRequirement_2}
              onValueChange={value => onChange?.({ benefitsFixedOvertimePayPaymentRequirement_2: value })}
            />
            <LocalText> 時間を超える時間外労働分についての割増賃金は追加で支給。</LocalText>
          </Paragraph>
        </LocalInputContainer>
      </FormRow>
      <FormRow />
      <FormRow>
        <LabelContainer>
          <EmphasizedItemLabel>
            1か月当たりの支払概算額
          </EmphasizedItemLabel>
          <NoteInLabel style={{ marginTop: 5 }}>
            基本賃金+時間外割増を除く諸手当
          </NoteInLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="189000"
            value={ec?.estimatedPaymentPerMonth}
            onValueChange={value => onChange?.({ estimatedPaymentPerMonth: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default BenefitsSection;
