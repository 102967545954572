import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  CheckboxesContainer,
  CheckboxContainer,
  RadioButtonsContainer,
  ItemTextArea,
} from "../../commonStyles";
import Checkbox from "../../../../../core/components/Checkbox";
import Text from "../../../../../core/components/Text";
import NumberField from "../../../../../core/components/NumberField";
import Radio from "../../../../../core/components/Radio";
import { EcIrregularHolidayUnit } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";

interface HolidaysSectionProps extends FormBlockSectionProps {}

const HolidaysSection: FunctionComponent<HolidaysSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>休日</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>定例日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxesContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysMonday}
                onValueChange={val => onChange?.({ holidaysFixedDaysMonday: val })}
              />
              <Text>月</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysTuesday}
                onValueChange={val => onChange?.({ holidaysFixedDaysTuesday: val })}
              />
              <Text>火</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysWednesday}
                onValueChange={val => onChange?.({ holidaysFixedDaysWednesday: val })}
              />
              <Text>水</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysThursday}
                onValueChange={val => onChange?.({ holidaysFixedDaysThursday: val })}
              />
              <Text>木</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysFriday}
                onValueChange={val => onChange?.({ holidaysFixedDaysFriday: val })}
              />
              <Text>金</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysSaturday}
                onValueChange={val => onChange?.({ holidaysFixedDaysSaturday: val })}
              />
              <Text>土</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysSunday}
                onValueChange={val => onChange?.({ holidaysFixedDaysSunday: val })}
              />
              <Text>日</Text>
            </CheckboxContainer>
          </CheckboxesContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>日本の国民の祝日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxContainer>
            <Checkbox 
              checked={!!ec?.holidaysNationalHolidaysOfJapan}
              onValueChange={val => onChange?.({ holidaysNationalHolidaysOfJapan: val })}
            />
            <Text>あり</Text>
          </CheckboxContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他定例休日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="夏季休暇5日、年末年始3日" 
            value={ec?.holidaysOtherFixedHolidays ?? ""}
            onTextChange={val => onChange?.({ holidaysOtherFixedHolidays: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>年間合計休日日数</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            placeholder="125"
            value={ec?.holidaysAnnualNumberOfHolidays}
            onValueChange={val => onChange?.({ holidaysAnnualNumberOfHolidays: val })}
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>非定例日（単位）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={
                ec?.holidaysIrregularDaysUnit === EcIrregularHolidayUnit.Week
              }
              value={EcIrregularHolidayUnit.Week}
              onValueChange={val => onChange?.({ holidaysIrregularDaysUnit: val })}
            >
              週当たり
            </Radio>
            <Radio
              checked={
                ec?.holidaysIrregularDaysUnit === EcIrregularHolidayUnit.Month
              }
              value={EcIrregularHolidayUnit.Month}
              onValueChange={val => onChange?.({ holidaysIrregularDaysUnit: val })}
            >
              月当たり
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>非定例日（日数）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            disabled={!!!EcIrregularHolidayUnit}
            placeholder={EcIrregularHolidayUnit ? "2" : "" }
            value={ec?.holidaysIrregularDaysNumberOfDays}
            onValueChange={val => onChange?.({ holidaysIrregularDaysNumberOfDays: val })}
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他非定例休日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            value={ec?.holidaysOtherIrregularHolidays ?? ""}
            onTextChange={val => onChange?.({ holidaysOtherIrregularHolidays: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>備考</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextArea
            value={ec?.holidaysNote ?? ""}
            onTextChange={val => onChange?.({ holidaysNote: val })}
            placeholder="詳細は、就業規則第◯条〜第◯条、第◯条〜第◯条"
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default HolidaysSection;
