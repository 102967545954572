import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import Row from "./Row";

interface PhoneNumberFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void;
} 

const LocalTextInput = styled(TextInput)`
  width: 52px;
  text-align: center;

  &::placeholder { 
    text-align: center;
  }
`;

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const PhoneNumberField: FunctionComponent<PhoneNumberFieldProps> = ({
  value = "",
  onValueChange,
  ...props
}) => {
  const [areaCode, setAreaCode] = useState<string>("");
  const [localAreaCode, setLocalAreaCode] = useState<string>("");
  const [subscriberNum, setSubscriberNum] = useState<string>("");
  const handleAreaCodeChange = (newAreaCode: string) => {
    if (/^\d{0,4}$/.test(newAreaCode))
    {
      setAreaCode(newAreaCode);
      onValueChange?.(`${newAreaCode}-${localAreaCode}-${subscriberNum}`);
    }
  };
  const handleLocalAreaCodeChange = (newLocalAreaCode: string) => {
    if (/^\d{0,4}$/.test(newLocalAreaCode))
    {
      setLocalAreaCode(newLocalAreaCode);
      onValueChange?.(`${areaCode}-${newLocalAreaCode}-${subscriberNum}`);
    }
  }
  const handleSubscriberNumChange = (newSubscriberNum: string) => {
    if (/^\d{0,4}$/.test(newSubscriberNum))
    {
      setSubscriberNum(newSubscriberNum);
      onValueChange?.(`${areaCode}-${localAreaCode}-${newSubscriberNum}`);
    }
  }

  useEffect(() => {    
    const vals = value ? value.toString().split('-') : ['', '', ''];
    setAreaCode(vals[0] || "");
    setLocalAreaCode(vals[1] || "");
    setSubscriberNum(vals[2] || "");
  }, [value])

  return (
    <Container>
      <LocalTextInput 
        placeholder="03"
        value={areaCode}
        onTextChange={handleAreaCodeChange}
        {...props}
      />
      -
      <LocalTextInput 
        placeholder="1234"
        value={localAreaCode}
        onTextChange={handleLocalAreaCodeChange}
        {...props}
      />
      -
      <LocalTextInput 
        placeholder="5678"
        value={subscriberNum}
        onTextChange={handleSubscriberNumChange}
        {...props}  
      />
    </Container>
  )
}

export default PhoneNumberField;