import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import Row from "./Row";
import { getAddressInformation } from "../apis/address";
import { useTranslation } from "react-i18next";

interface PostalCodeFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void;
  onPrefectureFetched?: (value: string) => void;
  onTownVillageFetched?: (value: string) => void;
  onCityWardFetched?: (value: string) => void;
} 

const LocalTextInput = styled(TextInput)`
  width: 52px;
  text-align: center;

  &::placeholder { 
    text-align: center;
  }
`;

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const PostalCodeField: FunctionComponent<PostalCodeFieldProps> = ({
  value,
  onValueChange,
  onPrefectureFetched,
  onTownVillageFetched,
  onCityWardFetched,
  ...props
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [postalDistrict, setPostalDistrict] = useState<string>("");
  const [townArea, setTownArea] = useState<string>("");
  const isReadyToFetchAddressInfo = (code: string) => {
    return (
      /^\d{7}$/.test(code.replace('-', '')) &&
      (onPrefectureFetched || onTownVillageFetched || onCityWardFetched)
    )
  };
  const handleAreaCodeChange = async (newPostalDistrict: string) => {
    if (!/^\d{0,3}$/.test(newPostalDistrict))
      return;

    const newPostalCode = `${newPostalDistrict}${townArea}`;
    const formattedPostalCode = `${newPostalDistrict}-${townArea}`;

    setPostalDistrict(newPostalDistrict);
    onValueChange?.(formattedPostalCode);

    if (!isReadyToFetchAddressInfo(newPostalCode)) 
      return;
    
    try {
      const addressInformation = await getAddressInformation(newPostalCode);
      onPrefectureFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaPrefecture
          : addressInformation.enPrefecture
        );
      onTownVillageFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaCity
          : addressInformation.enCity
        );
      onCityWardFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaTown
          : addressInformation.enTown
        );
    } catch {
    }
  };
  const handleLocalAreaCodeChange = async (newTownArea: string) => {
    if (!/^\d{0,4}$/.test(newTownArea))
      return;
    
    const newPostalCode = `${postalDistrict}${newTownArea}`;
    const formattedPostalCode = `${postalDistrict}-${newTownArea}`;

    setTownArea(newTownArea); 
    onValueChange?.(formattedPostalCode);

    if (!isReadyToFetchAddressInfo(newPostalCode))
      return;

    try {
      const addressInformation = await getAddressInformation(newPostalCode);
      onPrefectureFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaPrefecture
          : addressInformation.enPrefecture
        );
      onTownVillageFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaCity
          : addressInformation.enCity
        );
      onCityWardFetched?.(
        currentLanguage === 'ja'
          ? addressInformation.jaTown
          : addressInformation.enTown
        );
    } catch {
    }
  }

  useEffect(() => {
    if (!value)
      return;

    const codeStr = String(value);

    if (codeStr.includes('-')) {
      const [newPostalDistrict, newTownArea] = codeStr.split('-');
      setPostalDistrict(newPostalDistrict);
      setTownArea(newTownArea);
    }
    else {
      const newPostalDistrict = codeStr.slice(0, 3);
      const newTownArea = codeStr.slice(3);
      setPostalDistrict(newPostalDistrict);
      setTownArea(newTownArea || "");
    }
  }, [value]);

  return (
    <Container>
      <LocalTextInput 
        placeholder="302"
        value={postalDistrict}
        onTextChange={handleAreaCodeChange}
        style={{ width: '36px' }}
        {...props}
      />
      -
      <LocalTextInput 
        placeholder="1234"
        value={townArea}
        onTextChange={handleLocalAreaCodeChange}
        {...props}
      />
    </Container>
  )
}

export default PostalCodeField;