import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  RadioButtonsContainer,
  ItemTextArea,
  CheckboxContainer,
} from "../../commonStyles";
import NumberField from "../../../../../core/components/NumberField";
import TimeField from "../../../../../core/components/TimeField";
import AmountOfTimeField from "../../../../../core/components/AmountOfTimeField";
import Checkbox from "../../../../../core/components/Checkbox";
import Text from "../../../../../core/components/Text";
import Radio from "../../../../../core/components/Radio";
import { EcVariableWorkingHoursUnit } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";
import styled from "styled-components";
import { LocalTextInput } from "../../../../../job/pages/JobFormPage/commonStyle";

interface WorkHoursSectionProps extends FormBlockSectionProps {}


const VariableWorkingHoursUnitInput = styled(LocalTextInput)`
  width: 81px;
  margin-left: 10px;
  margin-right: 5px;
`;

const WorkHoursSection: FunctionComponent<WorkHoursSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>労働時間等</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>始業時間</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <TimeField 
            hours={ec?.workHoursStartTimeHour}
            minutes={ec?.workHoursStartTimeMinute}
            onHoursChange={val => onChange?.({ workHoursStartTimeHour: val })}
            onMinutesChange={val => onChange?.({ workHoursStartTimeMinute: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>終業時間</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <TimeField 
            hours={ec?.workHoursEndTimeHour}
            minutes={ec?.workHoursEndTimeMinute}
            onHoursChange={val => onChange?.({ workHoursEndTimeHour: val })}
            onMinutesChange={val => onChange?.({ workHoursEndTimeMinute: val })}
            placeholderForHours="17"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>1日の所定労働時間数</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <AmountOfTimeField 
            hours={ec?.workHoursNormalWorkingHour}
            minutes={ec?.workHoursNormalWorkingMinute}
            onHoursChange={val => onChange?.({ workHoursNormalWorkingHour: val })}
            onMinutesChange={val => onChange?.({ workHoursNormalWorkingMinute: val })}
            placeholderForHours="8"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>変形労働時間制</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxContainer>
            <Checkbox 
              checked={!!ec?.workHoursVariableWorkingHoursSystem}
              onValueChange={val => onChange?.({ workHoursVariableWorkingHoursSystem: val })}
            />
            <Text>あり　（
              <VariableWorkingHoursUnitInput
                placeholder="１年"
              />
              単位の変形労働時間制）
            </Text>
          </CheckboxContainer>
        </InputContainer>
      </FormRow>
      { ec?.workHoursVariableWorkingHoursSystem &&
        <FormRow>
          <LabelContainer>
            <ItemLabel>変形労働時間制（単位）</ItemLabel>
          </LabelContainer>
          <InputContainer>
            <RadioButtonsContainer>
              <Radio
                value={EcVariableWorkingHoursUnit.Year}
                checked={
                  ec?.workHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Year
                }
                onValueChange={val => onChange?.({ workHoursVariableWorkingHoursSystemUnit: val })}
              >
                1年
              </Radio>
              <Radio
                value={EcVariableWorkingHoursUnit.Month}
                checked={
                  ec?.workHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Month
                }
                onValueChange={val => onChange?.({ workHoursVariableWorkingHoursSystemUnit: val })}
              >
                1ヶ月
              </Radio>
              <Radio
                value={EcVariableWorkingHoursUnit.Week}
                checked={
                  ec?.workHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Week
                }
                onValueChange={val => onChange?.({ workHoursVariableWorkingHoursSystemUnit: val })}
              >
                1週間
              </Radio>
            </RadioButtonsContainer>
          </InputContainer>
        </FormRow>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>シフト制</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxContainer>
            <Checkbox 
              checked={!!ec?.workHoursShiftSystem}
              onValueChange={val => 
                onChange?.({ workHoursShiftSystem: val })}
            />
            <Text>あり</Text>
          </CheckboxContainer>
        </InputContainer>
      </FormRow>
      { !!ec?.workHoursShiftSystem &&
        <>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト1（始業・終業時刻）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <TimeField 
                hours={ec?.workHoursShiftSystem_1StartTimeHour}
                minutes={ec?.workHoursShiftSystem_1StartTimeMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_1StartTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_1StartTimeMinute: val })}
                placeholderForHours="08"
              /> 
              ~ 
              <TimeField 
                hours={ec?.workHoursShiftSystem_1EndTimeHour}
                minutes={ec?.workHoursShiftSystem_1EndTimeMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_1EndTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_1EndTimeMinute: val })}
                placeholderForHours="17"
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト1（適用日）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <ItemTextInput 
                placeholder="入力してください"
                value={ec?.workHoursShiftSystem_1ApplicationDate ?? ""}
                onTextChange={val => onChange?.({ workHoursShiftSystem_1ApplicationDate: val })}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
                シフト1（1日の所定労働時間）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <AmountOfTimeField 
                hours={ec?.workHoursShiftSystem_1StandardWorkingHour}
                minutes={ec?.workHoursShiftSystem_1StandardWorkingMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_1StandardWorkingHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_1StandardWorkingMinute: val })}
                placeholderForHours="8"
                upTo24Hours={true}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
                シフト2（始業・終業時刻）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <TimeField 
                hours={ec?.workHoursShiftSystem_2StartTimeHour}
                minutes={ec?.workHoursShiftSystem_2StartTimeMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_2StartTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_2StartTimeMinute: val })}
                placeholderForHours="08"
              /> 
              ~ 
              <TimeField 
                hours={ec?.workHoursShiftSystem_2EndTimeHour}
                minutes={ec?.workHoursShiftSystem_2EndTimeMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_2EndTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_2EndTimeMinute: val })}
                placeholderForHours="17"
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト2（適用日）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <ItemTextInput 
                placeholder="入力してください"
                value={ec?.workHoursShiftSystem_2ApplicationDate ?? ""}
                onTextChange={val => onChange?.({ workHoursShiftSystem_2ApplicationDate: val })}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト2（1日の所定労働時間）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <AmountOfTimeField 
                hours={ec?.workHoursShiftSystem_2StandardWorkingHour}
                minutes={ec?.workHoursShiftSystem_2StandardWorkingMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_2StandardWorkingHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_2StandardWorkingMinute: val })}
                placeholderForHours="8"
                upTo24Hours={true}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
                シフト3（始業・終業時刻）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <TimeField 
                hours={ec?.workHoursShiftSystem_3StartTimeHour}
                minutes={ec?.workHoursShiftSystem_3StartTimeMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_3StartTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_3StartTimeMinute: val })}
                placeholderForHours="08"
              /> 
              ~ 
              <TimeField 
                hours={ec?.workHoursShiftSystem_3EndTimeHour}
                minutes={ec?.workHoursShiftSystem_3EndTimeMinute}
                onHoursChange={ val => onChange?.({ workHoursShiftSystem_3EndTimeHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_3EndTimeMinute: val })}
                placeholderForHours="17"
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト3（適用日）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <ItemTextInput 
                placeholder="入力してください"
                value={ec?.workHoursShiftSystem_3ApplicationDate ?? ""}
                onTextChange={val => onChange?.({ workHoursShiftSystem_3ApplicationDate: val })}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>
              シフト3（1日の所定労働時間）
              </ItemLabel>
            </LabelContainer>
            <InputContainer>
              <AmountOfTimeField 
                hours={ec?.workHoursShiftSystem_3StandardWorkingHour}
                minutes={ec?.workHoursShiftSystem_3StandardWorkingMinute}
                onHoursChange={val => onChange?.({ workHoursShiftSystem_3StandardWorkingHour: val })}
                onMinutesChange={val => onChange?.({ workHoursShiftSystem_3StandardWorkingMinute: val })}
                placeholderForHours="8"
                upTo24Hours={true}
              />
            </InputContainer>
          </FormRow>    
        </>
      }
    
      <FormRow>
        <LabelContainer>
          <ItemLabel>休憩時間</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            placeholder="105"
            value={ec?.workHoursBreakTime}
            onValueChange={val => onChange?.({ workHoursBreakTime: val })}
          />
          <Text>分</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働時間数（週）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <AmountOfTimeField
            hours={ec?.workHoursWeeklyNormalWorkingHour}
            minutes={ec?.workHoursWeeklyNormalWorkingMinute}
            onHoursChange={val => onChange?.({ workHoursWeeklyNormalWorkingHour: val })}
            onMinutesChange={val => onChange?.({ workHoursWeeklyNormalWorkingMinute: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働時間数（月）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <AmountOfTimeField
            hours={ec?.workHoursMonthlyNormalWorkingHour}
            minutes={ec?.workHoursMonthlyNormalWorkingMinute}
            onHoursChange={val => onChange?.({ workHoursMonthlyNormalWorkingHour: val })}
            onMinutesChange={val => onChange?.({ workHoursMonthlyNormalWorkingMinute: val })}
            placeholderForHours="160"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働時間数（年）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <AmountOfTimeField
            hours={ec?.workHoursAnnualNormalWorkingHour}
            minutes={ec?.workHoursAnnualNormalWorkingMinute}
            onHoursChange={val => onChange?.({ workHoursAnnualNormalWorkingHour: val })}
            onMinutesChange={val => onChange?.({ workHoursAnnualNormalWorkingMinute: val })}
            placeholderForHours="1920"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働日数（週）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            value={ec?.workHoursWeeklyNormalWorkingDay_1}
            onValueChange={val => onChange?.({ workHoursWeeklyNormalWorkingDay_1: String(val) })}
            placeholder="3"
            allowDecimal
          />
          ~
          <NumberField
              value={ec?.workHoursWeeklyNormalWorkingDay_2}
              onValueChange={val => onChange?.({ workHoursWeeklyNormalWorkingDay_2: String(val) })}
              placeholder="5"
              allowDecimal
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働日数（月）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            value={ec?.workHoursMonthlyNormalWorkingDay}
            onValueChange={val => onChange?.({ workHoursMonthlyNormalWorkingDay: String(val) })}
            placeholder="20"
            allowDecimal
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定労働日数（年）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            value={ec?.workHoursAnnualNormalWorkingDay}
            onValueChange={val => onChange?.({ workHoursAnnualNormalWorkingDay: String(val) })}
            placeholder="240"
            allowDecimal
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>所定時間外労働</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.workHoursOvertimeWork === true}
              value={""}
              onValueChange={() => onChange?.({ 
                workHoursOvertimeWork: true
              })}
            >
              あり
            </Radio>
            <Radio
              checked={ec?.workHoursOvertimeWork === false}
              value={""}
              onValueChange={() => onChange?.({ 
                workHoursOvertimeWork: false 
              })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>備考</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextArea
            value={ec?.workHoursNote ?? ""}
            onTextChange={val => onChange?.({ workHoursNote: val })}
            placeholder="詳細は、就業規則第◯条〜第◯条、第◯条〜第◯条、第◯条〜第◯条"
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default WorkHoursSection;
