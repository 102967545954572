import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
} from "../../commonStyles";
import PhoneNumberField from "../../../../../core/components/PhoneNumberField";
import { FormBlockSectionProps } from "../../types";
import PostalCodeField from "../../../../../core/components/PostalCodeField";

interface EmployerSectionProps extends FormBlockSectionProps {}

const EmployerSection: FunctionComponent<EmployerSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>雇用者</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>会社/機関名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="日本工業株式会社" 
            value={ ec?.employerJaOrganizationName ?? ""}
            onTextChange={val => onChange?.({ employerJaOrganizationName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>会社/機関名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Nihon Kougyou" 
            value={ec?.employerEnOrganizationName ?? ""}
            onTextChange={val => onChange?.({ employerEnOrganizationName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <PostalCodeField
            value={ec?.employerPostalCode ?? ""}
            onValueChange={val => onChange?.({ employerPostalCode: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="東京都" 
            value={ec?.employerJaPrefecture ?? ""}
            onTextChange={val => onChange?.({ employerJaPrefecture: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="港区" 
            value={ec?.employerJaCityWard ?? ""}
            onTextChange={val => onChange?.({ employerJaCityWard: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="海岸" 
            value={ec?.employerJaTown ?? ""}
            onTextChange={val => onChange?.({ employerJaTown: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="１丁目９−１８" 
            value={ec?.employerJaAddressNumber ?? ""}
            onTextChange={val => onChange?.({ employerJaAddressNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="國際浜松町ビル 2階" 
            value={ec?.employerJaBuilding ?? ""}
            onTextChange={val => onChange?.({ employerJaBuilding: val })}
          />
        </InputContainer>
      </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>都道府県（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Tokyo"
                    value={ec?.employerEnPrefecture ?? ""}
                    onTextChange={val => onChange?.({ employerEnPrefecture: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>市区（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Minato-ku"
                    value={ec?.employerEnCityWard ?? ""}
                    onTextChange={val => onChange?.({ employerEnCityWard: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>町村（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="kaigan"
                    value={ec?.employerEnTown ?? ""}
                    onTextChange={val => onChange?.({ employerEnTown: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>番地（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="1-9-18"
                    value={ec?.employerEnAddressNumber ?? ""}
                    onTextChange={val => onChange?.({ employerEnAddressNumber: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>建物名等（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Kokusai Hamamatsucho Building 2F"
                    value={ec?.employerEnBuilding ?? ""}
                    onTextChange={val => onChange?.({ employerEnBuilding: val })}
                />
            </InputContainer>
        </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>電話番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <PhoneNumberField
            value={ec?.employerPhoneNumber ?? ""}
            onValueChange={val => onChange?.({ employerPhoneNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="特定太郎" 
            value={ec?.employerJaRepresentativeName ?? ""}
            onTextChange={val => onChange?.({ employerJaRepresentativeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Taro Tokutei" 
            value={ec?.employerEnRepresentativeName ?? ""}
            onTextChange={val => onChange?.({ employerEnRepresentativeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者役職</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="代表取締役" 
            value={ec?.employerRepresentativeTitle ?? ""}
            onTextChange={val => onChange?.({ employerRepresentativeTitle: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default EmployerSection;
