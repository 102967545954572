import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { FlexRowColumn, InputValue, Item5, Segment } from "../../commonStyles";
import { BlockNoteContainer, Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import { TemplateSectionProps } from "../../types";
import { Trans, useTranslation } from "react-i18next";
import { LanguageCode } from "../../../../../core/enums/language";
import { EcIrregularHolidayUnit } from "../../../../../core/enums/employmentConditions";
import { isEmpty } from "../../../../../core/utils";


interface HolidaysSectionProps extends TemplateSectionProps {
}

const HolidaysSection: FunctionComponent<HolidaysSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToJa = i18n.getFixedT(LanguageCode.Japanese);
  const tFixedToTransLang = i18n.getFixedT(transLang);
  const tFixedToTransLangWithPrefix = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLangWithPrefix(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;

  const [fixedDaysInJa, setFixedDaysInJa] = useState<string>("");
  const [fixedDaysInTransLang, setFixedDaysInTransLang] = useState<string>("");
  const [nationalHolidays, setNationalHolidays] = useState<boolean>();
  const [otherFixedHolidays, setOtherFixedHolidays] = useState<string>("");
  const [otherFixedHolidaysTrans, setOtherFixedHolidaysTrans] = useState<string>(""); 
  const [annualNumOfHolidays, setAnnualNumOfHolidays] = useState<number>();
  const [irregularHolidayUnitInJa, setIrregularHolidayUnitInJa] = useState<string>("");
  const [irregularHolidayUnitTransKey, setIrregularHolidayUnitTransKey] = useState<string>("");
  const [irregularNumOfHolidays, setIrregularNumOfHolidays] = useState<number>();
  const [otherIrregularHolidays, setOtherIrregularHolidays] = useState<string>("");
  const [otherIrregularHolidaysTrans, setOtherIrregularHolidaysTrans] = useState<string>("");
  const [notesOnHolidays, setNotesOnHolidays] = useState<string>("");
  const [notesOnHolidaysTrans, setNotesOnHolidaysTrans] = useState<string>("");

  const FixedHolidays = ({ children } : { children?: ReactNode }) => 
    isEmpty(children) ? <></> : <InputValue>{children}</InputValue>;
  
  const NationalHoliday = ({ children } : { children?: ReactNode }) => 
    isEmpty(children) ? <></> : <span>{children}</span>;

  const AnnualHolidayNum = ({ children } : { children?: ReactNode }) => 
    isEmpty(children) ? <></> :  <Item5><InputValue>{children}</InputValue></Item5> 

  const IrregularHolidayNum = ({ children } : { children?: ReactNode }) => 
    isEmpty(children) ? <></> :  <Item5><InputValue>{children}</InputValue></Item5>;

  useEffect(() => {

    if (!ec) 
      return;

    const daysOfWeekTransKeys = [
      "core.days.monday",
      "core.days.tuesday",
      "core.days.wednesday",
      "core.days.thursday",
      "core.days.friday",
      "core.days.saturday",
      "core.days.sunday"
    ];

    const filteredDaysOfWeekTransKeys = [
      ec.holidaysFixedDaysMonday,
      ec.holidaysFixedDaysTuesday,
      ec.holidaysFixedDaysWednesday,
      ec.holidaysFixedDaysThursday,
      ec.holidaysFixedDaysFriday,
      ec.holidaysFixedDaysSaturday,
      ec.holidaysFixedDaysSunday
    ]
    .map((v, i) => v ? daysOfWeekTransKeys[i] : '')
    .filter(v => v);

    setFixedDaysInJa(filteredDaysOfWeekTransKeys.map(key => tFixedToJa(key)).join('、'));
    setFixedDaysInTransLang(filteredDaysOfWeekTransKeys.map(key => tFixedToTransLang(key)).join(', '));
    setNationalHolidays(ec.holidaysNationalHolidaysOfJapan);
    setOtherFixedHolidays(ec.holidaysOtherFixedHolidays);
    setOtherFixedHolidaysTrans(transHandler.getTranslation("holidaysOtherFixedHolidays"));
    setAnnualNumOfHolidays(ec.holidaysAnnualNumberOfHolidays);
    
    switch (ec.holidaysIrregularDaysUnit) {
      case EcIrregularHolidayUnit.Month:
      {
        setIrregularHolidayUnitInJa(tFixedToJa("job.irregular_holidays_unit.month"));
        setIrregularHolidayUnitTransKey(getTKey("irregular_days_off_unit.month"));
        break;
      }

      case EcIrregularHolidayUnit.Week:
      {
        setIrregularHolidayUnitInJa(tFixedToJa("job.irregular_holidays_unit.week"));
        setIrregularHolidayUnitTransKey(getTKey("irregular_days_off_unit.week"));
        break;
      }
    }

    setIrregularNumOfHolidays(ec.holidaysIrregularDaysNumberOfDays);
    setOtherIrregularHolidays(ec.holidaysOtherIrregularHolidays);
    setOtherIrregularHolidaysTrans(transHandler.getTranslation("holidaysOtherIrregularHolidays"));
    setNotesOnHolidays(ec.holidaysNote);
    setNotesOnHolidaysTrans(transHandler.getTranslation("holidaysNote"));
        
  }, [ec])

  return (
    <Section>
      <SectionHeadingContainer>
        <span>V.</span>
        <Segment>
          <span>休日</span><br/>
          <span>{tFixed("holidays")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <span>定例日：
                    <span>
                      <Item5>毎週</Item5>
                      { fixedDaysInJa &&
                        <Item5><InputValue>{fixedDaysInJa}</InputValue></Item5>
                      }
                      <Item5>曜日、</Item5>
                    </span>
                  </span>
                  { nationalHolidays &&
                    <span>日本の国民の祝日、</span>
                  }
                  <span>その他（<InputValue>{otherFixedHolidays}</InputValue>）</span>                  
                  <span>（年間合計休日日数 <InputValue>{annualNumOfHolidays}</InputValue> 日）</span>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Trans
                    i18nKey={getTKey("regular_days_off")}
                    tOptions={tOptions}
                    values={{
                      f_hdays: fixedDaysInTransLang,
                      other: otherFixedHolidaysTrans,
                      annual_hols: annualNumOfHolidays
                    }}
                    components={{
                      seg: <Item5/>,
                      n_hdays: <NationalHoliday />,
                      f_hdays: <FixedHolidays />,
                      annual_hols: <InputValue />,
                      other: <InputValue />,
                    }}
                  />
                  <Trans
                    i18nKey={getTKey("annual_holiday_num")}
                    tOptions={tOptions}
                    values={{
                      annual_hols: annualNumOfHolidays
                    }}
                    components={{
                      seg: <Item5/>,
                      annual_hols: <AnnualHolidayNum/>,
                    }}
                  />
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <span>非定例日：{irregularHolidayUnitInJa} <InputValue>{irregularNumOfHolidays}</InputValue>日、</span>
                  <span>その他（<InputValue>{otherIrregularHolidays}</InputValue>）</span>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Trans
                    i18nKey={getTKey("irregular_days_off")}
                    tOptions={tOptions}
                    components={{
                      seg: <Item5/>
                    }}
                  />
                  <Trans
                    i18nKey={irregularHolidayUnitTransKey}
                    tOptions={tOptions}
                    values={{
                      num: irregularNumOfHolidays
                    }}
                    components={{
                      seg: <Item5/>,
                      hol_num: <IrregularHolidayNum/>
                    }}
                  />
                  <Trans
                    i18nKey={getTKey("other_irregular_days_off")}
                    tOptions={tOptions}
                    values={{
                      other: otherIrregularHolidaysTrans
                    }}
                    components={{
                      span: <span/>,
                      other: <InputValue />,
                    }}
                  />
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <BlockNoteContainer>
            <span>{notesOnHolidays}</span><br/>
            <span>{notesOnHolidaysTrans}</span>
          </BlockNoteContainer>
        </ListBlocksContainer>

      </Indent>
    </Section>
  )
}

export default HolidaysSection;